import { useRef } from 'react'
import * as Joi from '@hapi/joi'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'

import {
  ButtonV2,
  ComboBoxV3,
  FormItem,
  FormLabel,
  FormMessage,
  Icon,
  ModalV2,
  Tag,
  TextareaV2,
} from 'components'
import {
  getServiceOrderTags,
  useCreateIntervention,
} from 'services/serviceOrderV2'
import { useToast, useUserInfo } from 'shared/hooks'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import styles from './styles.module.scss'

interface ValidateSoProps {
  status: 'DISAPPROVE' | 'APPROVE'
}

interface FormProps {
  tags: { id: string; name: string }[]
  note: string
}

const ValidateSO = ({ status }: ValidateSoProps) => {
  const closeRef = useRef<HTMLButtonElement>(null)
  const { addToast } = useToast()

  const {
    userInfo: { id: userId },
  } = useUserInfo()
  const { serviceOrderId } = useParams()

  const isApproved = status === 'APPROVE'

  const schema = Joi.object({
    tags: Joi.array()
      .items(
        Joi.object({
          id: Joi.string(),
          name: Joi.string(),
        }),
      )
      .optional(),
    ...(isApproved
      ? {
          note: Joi.string().optional().allow(''),
        }
      : {
          note: Joi.string().required(),
        }),
  })

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormProps>({
    resolver: joiResolver(schema),
  })

  const { tags } = watch()

  const { serviceOrder } = useServiceOrderDetails()
  const { mutate, isPending } = useCreateIntervention(serviceOrderId)

  const handleMutateIntervention = (note: string, tags: { name: string }[]) => {
    mutate(
      {
        userId,
        typeName: 'STATUS_CHANGE',
        note: {
          note,
          hidden: false,
          userId,
          tags,
        },
        attributes: {
          technicianId: serviceOrder?.technician?.id,
          status: isApproved ? 'FINISHED' : 'DISAPPROVED',
        },
      },
      {
        onSuccess: () => {
          if (closeRef.current) {
            closeRef.current.click()
          }
        },
        onError: () => {
          addToast({
            message:
              'Erro ao enviar ordem de serviço para validação. Tente novamente',
            error: true,
            type: 'alert',
          })
        },
      },
    )
  }

  const onSubmit = (data: FormProps) => {
    const tags = data.tags?.map(({ name }) => ({
      name,
    }))

    handleMutateIntervention(data.note, tags)
  }

  return (
    <ModalV2.Content className={styles.container}>
      <ModalV2.Title>
        <Icon name="warning" color="accent-yellow-default" width={20} />
        {isApproved ? 'Aprovar' : 'Reprovar'} ordem de serviço
      </ModalV2.Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !(e.target instanceof HTMLTextAreaElement))
            e.preventDefault()
        }}
      >
        <FormItem>
          <FormLabel>Tags (opcional)</FormLabel>

          <ComboBoxV3.Root findOptions={getServiceOrderTags} valueKey="name">
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={tags} />
                {!!tags?.length && (
                  <ComboBoxV3.Options>
                    {tags.map((type) => (
                      <Tag key={type.id}>
                        <span>{type.name}</span>

                        <Icon
                          name="close-xlg"
                          width={8}
                          height={8}
                          onClick={() =>
                            setValue(
                              'tags',
                              tags.filter((item) => item.id !== type.id),
                            )
                          }
                        />
                      </Tag>
                    ))}
                  </ComboBoxV3.Options>
                )}
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map(({ id, name }) => (
                    <ComboBoxV3.Option
                      key={id}
                      shouldCloseGroup={false}
                      onClick={() => {
                        setValue('tags', [...(tags || []), { id, name }])
                      }}
                    >
                      {name}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>
        <FormItem>
          <FormLabel>Observação {isApproved ? '(opcional)' : ''}</FormLabel>
          <TextareaV2
            {...register('note')}
            maxLength={5000}
            rows={5}
            placeholder={`Digite um comentário sobre a ${isApproved ? 'aprovação' : 'reprovação'} desta Ordem de Serviço`}
          />
          <FormMessage>
            {5000 - (watch('note')?.length || 0)} caracteres restantes
          </FormMessage>
        </FormItem>

        <ModalV2.Footer>
          <ModalV2.Close asChild>
            <ButtonV2 appearance="tertiary" ref={closeRef}>
              Cancelar
            </ButtonV2>
          </ModalV2.Close>
          {isApproved ? (
            <ButtonV2 disabled={!isValid || isPending} type="submit">
              Aprovar
            </ButtonV2>
          ) : (
            <ButtonV2
              color="red"
              type="submit"
              disabled={!isValid || isPending}
            >
              Reprovar
            </ButtonV2>
          )}
        </ModalV2.Footer>
      </form>
    </ModalV2.Content>
  )
}

export default ValidateSO
