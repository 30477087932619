import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { maskedDateTime } from 'utilities/date'
import { useQueryOccurrence } from 'services/occurrence'
import { QueryOccurrence } from 'services/occurrence/types'
import { handleRenderState } from 'utilities/handleRenderState'
import {
  EmptyStateV2,
  ErrorState,
  Icon,
  IconButton,
  OccurrenceTag,
  PaginationV2,
  TableV2,
} from 'components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'
import { buildPath, paths } from 'routes'

const columnHelper = createColumnHelper<QueryOccurrence>()

const OccurrenceTable = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { serviceOrder } = useServiceOrderDetails()

  const page = searchParams.get('page')

  const { data, isLoading, isError } = useQueryOccurrence({
    accountId: serviceOrder?.accountId,
    fields: ['id', 'number', 'createdAt', 'typeName', 'title', 'finishedAt'],
    partition: ['id', 'name'],
    size: 15,
    page: Number(page) || 1,
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('number', {
        id: 'number',
        header: 'Número',
      }),
      columnHelper.accessor((row) => row, {
        id: 'type',
        header: 'Tipo',
        cell: (info) => {
          const title = info.getValue().title

          return (
            <OccurrenceTag typeName={info.getValue().typeName}>
              {(label) => `${label} ${title ? `- ${title}` : ''}`}
            </OccurrenceTag>
          )
        },
      }),
      columnHelper.accessor('partitions', {
        header: 'Partição',
        cell: (info) => info.getValue()?.[0]?.name,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Abertura',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor('finishedAt', {
        header: 'Fechamento',
        cell: (info) => {
          const finishedAt = info.getValue()
          return <>{finishedAt ? maskedDateTime(finishedAt) : '--'}</>
        },
      }),
      columnHelper.accessor(({ id }) => id, {
        id: 'action',
        header: '',
        size: 38,
        cell: (info) => (
          <IconButton
            onClick={() =>
              navigate(
                buildPath({
                  path: paths.occurrence.attendance,
                  params: { occurrenceId: info.getValue() },
                }),
                {
                  state: {
                    readonly: true,
                  },
                },
              )
            }
          >
            <Icon name="view" />
          </IconButton>
        ),
      }),
    ],
    [columnHelper],
  )

  const table = useReactTable({
    columns,
    data: data?.data || [],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {
        {
          view: (
            <>
              <TableV2.Root>
                <TableV2.Header>
                  {table?.getHeaderGroups().map((headerGroup) => (
                    <TableV2.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableV2.Column
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableV2.Column>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Header>
                <TableV2.Body>
                  {table?.getRowModel().rows.map((row) => (
                    <TableV2.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableV2.Cell
                          key={cell.id}
                          className={styles[cell.column.id]}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableV2.Cell>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Body>
              </TableV2.Root>
              <PaginationV2 totalElements={data?.totalElements} />
            </>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </>
  )
}

export default OccurrenceTable
