import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { AccountDetails, SODetails, TimeLine } from './containers'

import styles from './styles.module.scss'

const Details = () => {
  return (
    <div className={styles.container}>
      <AccountDetails />
      <OverlayScrollbarsComponent
        defer
        options={{
          scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 0.3,
          },
        }}
        className={styles.content}
      >
        <SODetails />
        <hr className={styles.divider} />
        <TimeLine />
      </OverlayScrollbarsComponent>
    </div>
  )
}

export default Details
