import { useNavigate } from 'react-router-dom'

import { dateNow } from 'utilities/date'
import { useQueryOccurrence } from 'services/occurrence'
import { handleRenderState } from 'utilities/handleRenderState'
import { EmptyStateV2, ErrorState, Icon, OccurrenceTag } from 'components'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'
import { buildPath, paths } from 'routes'

const OccurrenceHistory = () => {
  const navigate = useNavigate()
  const { serviceOrder } = useServiceOrderDetails()

  const { data, isLoading, isError } = useQueryOccurrence({
    accountId: serviceOrder?.accountId,
    fields: ['id', 'number', 'createdAt', 'typeName', 'title', 'totalEvents'],
    partition: ['id', 'name'],
    page: 1,
    size: 4,
  })

  return (
    <ul className={styles.history}>
      {
        {
          view: data?.data?.map((occurrence) => (
            <li
              className={styles[occurrence.typeName]}
              key={occurrence.id}
              onClick={() =>
                navigate(
                  buildPath({
                    path: paths.occurrence.attendance,
                    params: { occurrenceId: occurrence.id },
                  }),
                  {
                    state: {
                      readonly: true,
                    },
                  },
                )
              }
            >
              <div>
                <OccurrenceTag typeName={occurrence.typeName} />

                {!!occurrence.totalEvents && (
                  <>
                    <Icon name="event" width={12} color="element-default" />
                    <span>{occurrence.totalEvents}</span>
                  </>
                )}
              </div>
              <span>
                {occurrence.title || 'Ocorrência'} {`#${occurrence.number}`}
              </span>
              <span>{dateNow(occurrence.createdAt)}</span>
            </li>
          )),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </ul>
  )
}

export default OccurrenceHistory
