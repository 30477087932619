import { useCallback, useRef } from 'react'

import { ButtonV2, Icon, ModalV2 } from 'components'

import {
  useMaintenanceMode,
  useDefaultMode,
  useCreateIntervention,
} from 'services'
import { useToast, useUserInfo } from 'shared/hooks'
import { usePersistentTab } from 'domains/occurrence/hooks'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import styles from './styles.module.scss'
import { buildPath, paths } from 'routes'

const Execute = () => {
  const { addToast } = useToast()
  const { userInfo } = useUserInfo()
  const closeRef = useRef<HTMLButtonElement>(null)
  const { serviceOrder } = useServiceOrderDetails()
  const { replaceUrl } = usePersistentTab('service-order-account-tab')

  const { mutate: mutateMaintenanceCommand, isPending: maintenanceIsPending } =
    useMaintenanceMode(serviceOrder?.centralId)

  const { mutate: mutateDefaultCommand, isPending: defaultIsPending } =
    useDefaultMode(serviceOrder?.centralId)

  const { mutate: mutateIntervention, isPending } = useCreateIntervention(
    serviceOrder?.id,
  )

  const onInspectAccount = useCallback(() => {
    if (serviceOrder?.accountId) {
      replaceUrl(
        buildPath({
          path: paths.account.details,
          params: { accountId: serviceOrder.accountId },
        }),
      )
    }
  }, [replaceUrl, serviceOrder])

  const handleMutateIntervention = () =>
    mutateIntervention(
      {
        typeName: 'STATUS_CHANGE',
        userId: userInfo.id,
        attributes: {
          status: 'IN_PROGRESS',
          technicianId: serviceOrder?.technician?.id || userInfo.id,
        },
      },
      {
        onSuccess: () => {
          onInspectAccount()

          if (closeRef.current) {
            closeRef.current.click()
          }
        },
        onError: () => {
          addToast({
            message: 'Erro ao executar ordem de serviço. Tente novamente.',
            error: true,
            type: 'alert',
          })

          if (serviceOrder?.centralId) {
            mutateDefaultCommand({ userId: userInfo.id })
          }
        },
      },
    )

  const handleSubmit = () => {
    if (serviceOrder?.centralId) {
      mutateMaintenanceCommand(
        { userId: userInfo.id },
        {
          onSuccess: handleMutateIntervention,
          onError: () =>
            addToast({
              message:
                'Erro ao executar ordem de serviço e colocar a central em modo manutenção. Tente novamente.',
              error: true,
              type: 'alert',
            }),
        },
      )
    } else {
      handleMutateIntervention()
    }
  }

  return (
    <ModalV2.Content className={styles.container}>
      <ModalV2.Title>
        <Icon name="warning" color="accent-yellow-default" width={20} />
        Deseja iniciar a execução da ordem de serviço?
      </ModalV2.Title>
      <p>Você será direcionado para a área do cliente.</p>

      <ModalV2.Footer>
        <ModalV2.Close asChild>
          <ButtonV2 appearance="tertiary" ref={closeRef}>
            Cancelar
          </ButtonV2>
        </ModalV2.Close>
        <ButtonV2
          onClick={handleSubmit}
          disabled={isPending || maintenanceIsPending || defaultIsPending}
        >
          Executar
        </ButtonV2>
      </ModalV2.Footer>
    </ModalV2.Content>
  )
}

export default Execute
