import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { maskedDateTime } from 'utilities/date'
import { QuerySO, useQuerySO, serviceOrderTypes } from 'services/serviceOrderV2'
import {
  EmptyStateV2,
  ErrorState,
  Icon,
  IconButton,
  PaginationV2,
  SOTag,
  TableV2,
} from 'components'
import { handleRenderState } from 'utilities/handleRenderState'
import { useServiceOrderDetails } from 'domains/serviceOrder/screens/Details/context'

import Loading from './Loading'
import styles from './styles.module.scss'
import { buildPath, paths } from 'routes'

const columnHelper = createColumnHelper<QuerySO>()

const SOTable = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { serviceOrder } = useServiceOrderDetails()

  const page = searchParams.get('page')
  const { serviceOderId } = useParams()

  const { data, isLoading, isError } = useQuerySO({
    accountIds: [serviceOrder?.accountId],
    fields: [
      'id',
      'number',
      'status',
      'serviceOrderType',
      'createdAt',
      'closedAt',
    ],
    technician: ['id', 'name'],
    account: ['id', 'name'],
    page: Number(page) || 1,
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'id',
        header: 'Número',
        cell: (info) => (
          <div className={styles.cell}>
            {info.getValue().number}
            <SOTag status={info.getValue().status} />
          </div>
        ),
      }),
      columnHelper.accessor('serviceOrderType', {
        header: 'Tipo',
        cell: (info) => <>{serviceOrderTypes[info.getValue()]}</>,
      }),
      columnHelper.accessor('technician', {
        header: 'Técnico',
        cell: (info) => info.getValue()?.name,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Abertura',
        cell: (info) => <>{maskedDateTime(info.getValue())}</>,
      }),
      columnHelper.accessor('closedAt', {
        header: 'Fechamento',
        cell: (info) => (
          <>{info.getValue() ? maskedDateTime(info.getValue()) : '--'}</>
        ),
      }),
      columnHelper.accessor(({ id }) => id, {
        id: 'action',
        header: '',
        size: 38,
        cell: (info) =>
          serviceOderId !== info.getValue() && (
            <IconButton
              onClick={() =>
                navigate(
                  buildPath({
                    path: paths.serviceOrder.details,
                    params: { serviceOrderId: info.getValue() },
                  }),
                )
              }
            >
              <Icon name="view" />
            </IconButton>
          ),
      }),
    ],
    [columnHelper],
  )

  const filteredData = useMemo(
    () => data?.data?.filter((data) => data?.id !== serviceOderId) || [],
    [data],
  )

  const table = useReactTable({
    columns,
    data: filteredData,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      {
        {
          view: (
            <>
              <TableV2.Root className={styles.container}>
                <TableV2.Header>
                  {table?.getHeaderGroups().map((headerGroup) => (
                    <TableV2.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableV2.Column
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.column.getSize() }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableV2.Column>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Header>
                <TableV2.Body>
                  {table?.getRowModel().rows.map((row) => (
                    <TableV2.Row key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableV2.Cell
                          key={cell.id}
                          className={styles[cell.column.id]}
                          style={{ width: cell.column.getSize() }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableV2.Cell>
                      ))}
                    </TableV2.Row>
                  ))}
                </TableV2.Body>
              </TableV2.Root>
              <PaginationV2 totalElements={data?.totalElements} />
            </>
          ),
          loading: <Loading />,
          error: <ErrorState />,
          empty: <EmptyStateV2 />,
        }[
          handleRenderState({
            data,
            isLoading,
            isError,
            isEmpty: !data?.totalElements,
          })
        ]
      }
    </>
  )
}

export default SOTable
